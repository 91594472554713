export const TITLE = "Password Generator"
export const DESCRIPTION = "Use this tool to create secure, random passwords instantly."
export const COPIED = "Copied"
export const COPY_PASSWORD = "Copy Password"
export const ADVANCED = "ADVANCED"
export const PASSWORD_LENGTH = "Password Length"
export const LOWERCASE = "Lowercase"
export const UPPERCASE = "Uppercase"
export const NUMBERS = "Numbers"
export const SYMBOLS = "Symbols"
export const EXCLUDE_CHARACTERS = "Exclude look-alike characters"
export const EXCLUDE_CHARACTERS_TITLE = "Exclude Characters"
export const DO_NOT_INCLUDE = "Do not include"
export const DO_NOT_INCLUDE_PLACEHOLDER = "Custom characters to exclude"
export const GENERATE_PASSWORD = "Generate Password"
export const NO_OPTION_ERROR_TEXT = "Select at least one option"
export const SYMBOL_ONLY_ERROR_TEXT = "You can not select only the symbol option"
export const FAQ_TITLE = "Frequently Asked Questions"
export const FAQ = [
  {
    question: 'What is a password?',
    answer: 'A password is a user&#8242;s unique identity to keep their details safe by privatizing access. These days, privatizing everything has been widely popular, and let&#8242;s say a must, so everything has been secured with a password, whether it&#8242;s your phone, laptop, email, or even access to your home, etc.'
  },
  {
    question: 'What is a password generator?',
    answer: 'A <a href="https://app.randomstrongpasswordgenerator.com/#password-generator">password generator</a> is a program that automatically generates very secure and unique passwords for you with a click of a button.'
  },
  {
    question: 'What makes a password secure?',
    answer: 'Password cracking programs will decrypt passwords based on the most common user mistakes used during weak password creation. How to avoid your passwords from being hacked? Learn how to create strong passwords:<br><br>A strong password should contain at least 15 characters, comprising both uppercase and lowercase letters. It should also include a mixture of numbers and symbols and be completely different from your previous passwords.<br><br>A strong password should not contain a common name, such as your name, website username, name of your pet, or a family member&#8242;s name. In addition, a strong password should not contain details of your email address, date of birth, postal address, keyboard pattern, or any dictionary word.<br><br>Use this strong, random, and <a href="https://app.randomstrongpasswordgenerator.com/#password-generator">secure password generator</a> on the top of the page to create extremely hard-to-hack passwords for your online security.'
  },
  {
    question: 'Ways to keep your passwords safe',
    answer: 'So, you have your passwords set up nicely, and you feel that it&#8242;s all under control. Now you should take care to keep them safe. Below is the list of rules that keep you out of trouble with passwords;'
  },
  {
    question: 'Don\'t give your passwords to others',
    answer: 'If someone absolutely must access your account, change your password before sharing it, then change it back after their access is no longer required.'
  },
  {
    question: 'Never respond to an email asking for your password',
    answer: 'Remember this! It&#8242;s crucial! No exceptions! No legitimate service will ever request you to send them your password. These emails are called phishing scams and are very common. Criminals create and send these emails that sound and look authentic with all the good graphics and logos in place. They also embed links in these emails that, when clicked, navigate you to a fraudulent website that looks exactly like the original but created by the criminals. Criminals create these websites specifically to fool people into believing they are on the authentic website and typing their login details.'
  },
  {
    question: 'Never follow a link in an email pointing to a site that requires a login',
    answer: 'This rule is just as important as the previous one! There are also no exceptions! Even if you are sure the email is legitimate, go to the website by either using a previously saved bookmark or by typing in the site address you usually use to visit the site. Do not type in the link provided in the email!'
  },
  {
    question: 'Treat as compromised any password that has been sent in an email to you and never send passwords out in an email to anyone else',
    answer: 'Emails travel through Internet as clear text and can be seen by others. Therefore, if you request a forgotten password to be sent to you, change your password as soon as you receive it. Likewise, if you request a password reset and a reset link is sent to you, use the link as soon as you get the email, do not wait because someone else can use that link to reset the password and gain access to your account.'
  },
  {
    question: 'Don\'t use public computers (at Internet cafes, hotels, etc.) to authenticate on any website with your login details',
    answer: 'No exceptions! Public computers are swarmed with spyware and key-loggers, which are invisible to the naked eye. These nasties will capture, log and send to their owner everything you do on the computer, including websites you visit and login details you type in.'
  },
   {
    question: 'Only ever login through HTTPS/SSL secures web pages if the account is of any value to you',
    answer: 'This is very important! If you do not know what HTTPS, SSL secure web page is, don&#8242;t be scared. It is pretty simple. To find out if you are on an HTTPS, SSL secure page, perform the following two-step check:<br><br>1.) Look in the address bar of your browser. The web page&#8242;s address must start with HTTPS:// - not HTTP://. If the address begins with HTTP://, this is not a secure web page. If it starts with "HTTPS://", proceed to the next step.<br><br>2.) There is a de facto standard among web browsers to display a "Lock" icon in the address bar or the browser&#8242;s status bar. Click (or double-click) on it to see details of the site&#8242;s security certificate.<br><br>This is important to know where and how your browser indicates that the web page is secure. Check your browser&#8242;s help if you are unsure where the "Lock" icon is.<br><br>It is also essential to click/double-click the lock icon on a new website you are visiting because some fraudulent websites are built with an imitation bar on the web page to imitate the lock icon of your browser! Therefore it is necessary to test the functionality built into this "Lock" icon.<br><br>Some services do not force you to use the secure login page. For sites like this, you can use the following trick:<br><br>To edit the address of the login page and replace "HTTP" with "HTTPS" at the beginning of the address; then<br><br>Press "Enter".<br><br>If a secure login page loads successfully, go and perform the two-step check described above; otherwise<br><br>Instead, the website does not offer a secure HTTPS/SSL connection if you get an error page. Any legitimate website that deals with financials or any other real valuables must provide a secure login facility. Otherwise, it is strongly NOT advised to use their services.'
  },
  {
    question: 'Use a password manager with anti-phishing protection',
    answer: 'While it is possible to fool a human eye with an authentic-looking fraudulent login screen and java-scripting techniques, a specially designed anti-phishing software will not fall for the same tricks.<br><br>For example, <a href="https://1password.com/" rel="noreferrer" target="_blank">1Password</a> password manager or <a href="https://www.lastpass.com/" rel="noreferrer" target="_blank">LastPass</a> has integrated anti-phishing protection, so if you&#8242;re on a fraudulent login page, 1Password will spot this.'
  },
]